<template>
  <div>
    <b-navbar fixed-top>
      <template #brand>
        <b-navbar-item>{{ siteName }} v1 API Documentation</b-navbar-item>
      </template>
    </b-navbar>
    <div class="documentation-content p-3" style="width: 960px; margin: 0 auto">
      <div class="card">
        <div class="card-header has-background-dark">
          <div class="card-header-title has-text-white">Creating request signature</div>
        </div>
        <div class="card-content">
          <b-tabs type="is-toggle" v-model="tab" size="is-small">
            <b-tab-item label="Php">
              <p class="mb-2 mt-2">Below is the sample encryption code for Php. You can use it directly. For encryption, you
                need to encrypt it with the ClientSecret provided to you when creating your site. All values in the sent
                request are encrypted with the code below.</p>
              <codemirror v-model="phpCode" :options="phpOption" v-if="tab === 0"/>
            </b-tab-item>
            <b-tab-item label="NodeJs">
              <p class="mb-2 mt-2">Below is the sample encryption code for Nodejs. You can use it directly. For encryption,
                you need to encrypt it with the ClientSecret provided to you when creating your site. All values in the
                sent request are encrypted with the code below.</p>
              <codemirror v-model="nodeCode" :options="nodeOption" v-if="tab === 1"/>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
          <b-collapse
              class="card"
              animation="slide"
              v-for="(collapse, index) of collapses"
              :key="index"
              :open="isOpen === index"
              @open="isOpen = index"
              v-if="access[siteName][collapse.enum]"
          >
            <template #trigger="props">
              <div class="card-header has-background-dark" role="button">
                <p class="card-header-title has-text-white">{{ collapse.title }}</p>
                <a class="card-header-icon">
                  <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"/>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                {{ collapse.text }}
                <hr/>
                <div class="endpoint-content">
                  <div class="endpoint-method">
                    <b-tag size="is-small" type="is-success">{{ collapse.method }}</b-tag>
                    <span class="has-text-link-dark ml-3">{{ collapse.url }}</span>
                  </div>
                  <hr/>
                  <b-tabs type="is-toggle" size="is-small">
                    <b-tab-item :label="parameter.title" v-for="(parameter, key) in collapse.parameters" :key="key">
                      <table>
                        <thead>
                        <tr>
                          <th>Parameter</th>
                          <th>Description</th>
                          <th>Type</th>
                          <th v-if="parameter.title !== 'Response'">Required</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(param, key) in parameter.items" :key="key">
                          <td>{{ param.parameter }}</td>
                          <td>{{ param.description }}</td>
                          <td>{{ param.type }}</td>
                          <td v-if="parameter.title !== 'Response'">{{ param.required ? '*Required' : 'Optional' }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </b-tab-item>
                  </b-tabs>
                </div>
              </div>
            </div>
          </b-collapse>
      <div class="card">
        <div class="card-header has-background-dark">
          <div class="card-header-title has-text-white">Callback Return Information</div>
        </div>
        <div class="card-content">
          <div class="my-3">As soon as the payment status changes, a result like the following will be sent to the
            callback address you provided while creating the site, via POST method. <br><br>

            <b>!!NOTE!!:</b> You should return {status: true, message: 'ok'} as a response when you receive a successful callback.
          </div>
          <hr>
          <div class="content">

            <table>
              <thead>
              <tr>
                <th>Parameter</th>
                <th>Description</th>
                <th>Type</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>transactionId</td>
                <td>transaction id</td>
                <td>string</td>
              </tr>
              <tr>
                <td>amount</td>
                <td>approved amount</td>
                <td>number</td>
              </tr>
              <tr>
                <td>userId</td>
                <td>userId of member</td>
                <td>string</td>
              </tr>
              <tr>
                <td>name</td>
                <td>name of member</td>
                <td>string</td>
              </tr>
              <tr>
                <td>username</td>
                <td>username of member</td>
                <td>string</td>
              </tr>
              <tr>
                <td>processId</td>
                <td>processId of your side</td>
                <td>string</td>
              </tr>
              <tr>
                <td>status</td>
                <td>'PENDING', 'SUCCESSFUL' or 'UNSUCCESSFUL' status of transaction</td>
                <td>string</td>
              </tr>
              <tr>
                <td>statusReason</td>
                <td>status reason of transaction</td>
                <td>string</td>
              </tr>
              <tr>
                <td>type</td>
                <td>'DEPOSIT' or 'WITHDRAW' transaction type</td>
                <td>string</td>
              </tr>
              <tr>
                <td>hash</td>
                <td>md5(transactionId.userId.username.amount.secretKey)</td>
                <td>string</td>
              </tr>
              <tr>
                <td>paymentMethod</td>
                <td>Deposit Payment Method</td>
                <td>string</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dedent from 'dedent'
import {codemirror} from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
// language
import 'codemirror/mode/php/php.js'
import 'codemirror/mode/javascript/javascript.js'

// theme css
import 'codemirror/theme/monokai.css'
// theme css
import 'codemirror/theme/cobalt.css'

// require active-line.js
import 'codemirror/addon/selection/active-line.js'

export default {
  name: 'Documentation',
  components: {codemirror},
  data() {
    return {
      tab: 0,
      isOpen: 0,
      phpOption: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: 'application/x-httpd-php',
        lineWrapping: true,
        theme: 'monokai'
      },
      nodeOption: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: 'application/javascript',
        lineWrapping: true,
        theme: 'monokai'
      },
      phpCode: dedent`<?php
    $hashedRequest =  base64_encode(hash_hmac('sha256', '<your-request>', '<your-client-secret>', true));
    // <your-request> = You can create by imploding all the request you send with '&'. So as an example: amount=100&token=asd123&currency=123123
    // <your-client-secret> = is your appSecret. Do not share with anyone.
    // Sample Signature = +GhKHt/zgo3ospcYSF5yYVvK++9xfS24VuZdhvgwFcc=
?>`,
      nodeCode: dedent`const crypto = require('crypto')
    const hmac = crypto.createHmac('sha256', '<your-client-secret>')
    hmac.update('<your-request>')
    const hashedRequest = new Buffer.from(hmac.digest('base64')).toString()
    // <your-request> = You can create by imploding all the request you send with '&'. So as an example: amount=100&token=asd123&currency=123123
    // <your-client-secret> = is your appSecret. Do not share with anyone.
    // Sample Signature = +GhKHt/zgo3ospcYSF5yYVvK++9xfS24VuZdhvgwFcc=
   `,
      access: {
        "trustpay.tech": {
          "PAPARA": true,
          "BANK_TRANSFER": true,
          "PAYFIX": true,
          "CARD": true
        },
        "rabbitpay.dev": {
          "PAPARA_TO_IBAN": true,
          "CARD": true
        },
        "boldpay.xyz": {
          "PAPARA": true,
          "PAYFIX": true,
          "PAYCO": true,
          "CARD": true,
          "CMT": true,
          "PEP": true,
        },
      }
    }
  },
  computed: {
    collapses() {
      return [
        {
          title: 'Create Papara to Iban Transfer',
          text: 'It creates a money transfer from your member\'s papara account to the bank account of your choice.',
          url: 'https://api.' + this.siteName + '/v1/integration/papara2iban',
          method: 'POST',
          enum: 'PAPARA_TO_IBAN',
          parameters: [
            {
              title: 'Body',
              items: [
                {required: true, parameter: 'amount', description: 'Amount requested to deposit', type: 'number'},
                {required: true, parameter: 'userId', description: 'ID of member', type: 'string'},
                {required: true, parameter: 'name', description: 'Full name of member', type: 'string'},
                {required: true, parameter: 'username', description: 'Username of member', type: 'string'},
                {required: true, parameter: 'processId', description: 'Transaction number on your side', type: 'string'},
                {
                  required: true,
                  parameter: 'successRedirectUrl',
                  description: 'If the operation is successful, the address you want the user to be forwarded to',
                  type: 'string'
                },
                {
                  required: true,
                  parameter: 'failRedirectUrl',
                  description: 'If the operation is unsuccessful, the address you want the user to be forwarded to',
                  type: 'string'
                }
              ]
            },
            {
              title: 'Header',
              items: [
                {required: true, parameter: 'sign', description: 'Your Request Signature', type: 'string'},
                {required: true, parameter: 'appKey', description: 'Your Application Key', type: 'string'}
              ]
            },
            {
              title: 'Response',
              items: [
                {parameter: 'status', description: 'Process status', type: 'boolean'},
                {parameter: 'transactionId', description: 'Transaction ID', type: 'string'},
                {parameter: 'message', description: 'Process message', type: 'string'},
                {
                  parameter: 'redirectUrl',
                  description: 'In order for the member to pay with a papara account, you must display this field in an iframe.',
                  type: 'string'
                },
              ]
            }
          ]
        },
        {
          title: 'Create Papara Deposit',
          text: 'It creates a deposit transaction through the bank you have chosen for your site.',
          url: 'https://api.' + this.siteName + '/v1/integration/papara/deposit',
          method: 'POST',
          enum: 'PAPARA',
          parameters: [
            {
              title: 'Body',
              items: [
                {required: true, parameter: 'amount', description: 'Amount requested to deposit', type: 'number'},
                {required: true, parameter: 'userId', description: 'ID of member', type: 'string'},
                {required: true, parameter: 'name', description: 'Full name of member', type: 'string'},
                {required: true, parameter: 'username', description: 'Username of member', type: 'string'},
                {
                  required: true,
                  parameter: 'processId',
                  description: 'Transaction number on your side',
                  type: 'string'
                },
                {
                  required: false,
                  parameter: 'successRedirectUrl',
                  description: 'If the operation is successful, the address you want the user to be forwarded to',
                  type: 'string'
                },
                {
                  required: false,
                  parameter: 'failRedirectUrl',
                  description: 'If the operation is unsuccessful, the address you want the user to be forwarded to',
                  type: 'string'
                },
                {
                  required: false,
                  parameter: 'responseType',
                  description: 'If left blank, it creates a link for the payment form page. If the value "MANUAL" is sent, it returns the account information to be invested.',
                  type: 'string'
                }
              ]
            },
            {
              title: 'Header',
              items: [
                {required: true, parameter: 'sign', description: 'Your Request Signature', type: 'string'},
                {required: true, parameter: 'appKey', description: 'Your Application Key', type: 'string'}
              ]
            },
            {
              title: 'Response',
              items: [
                {parameter: 'status', description: 'Process status', type: 'boolean'},
                {parameter: 'transactionId', description: 'Transaction ID', type: 'string'},
                {
                  parameter: 'depositUrl',
                  description: 'The url you need to direct your member to. After the member clicks the "Ödemeyi Gerçekleştirdim" button, the system will go to the papara approval.',
                  type: 'string'
                },
                {
                  parameter: 'paparaAccountName',
                  description: 'If the "responseType" field is "MANUAL" when creating the investment request, the name of the account to be invested is returned.',
                  type: 'string'
                },
                {
                  parameter: 'paparaAccountNumber',
                  description: 'If the "responseType" field is "MANUAL" when creating the investment request, the number of the account to be invested is returned.',
                  type: 'string'
                }
              ]
            }
          ]
        },
        {
          title: 'Create Withdraw',
          text: 'It creates a withdraw transaction through the bank you have chosen for your site.',
          url: 'https://api.' + this.siteName + '/v1/integration/withdraw',
          method: 'POST',
          enum: 'WITHDRAW',
          parameters: [
            {
              title: 'Body',
              items: [
                {required: true, parameter: 'amount', description: 'Amount requested to deposit', type: 'number'},
                {required: true, parameter: 'userId', description: 'ID of member', type: 'string'},
                {required: true, parameter: 'name', description: 'Full name of member', type: 'string'},
                {required: true, parameter: 'username', description: 'Username of member', type: 'string'},
                {required: true, parameter: 'processId', description: 'Transaction number on your side', type: 'string'},
                {required: true, parameter: 'accountName', description: 'Transaction number on your side', type: 'string'},
                {required: true, parameter: 'iban', description: 'Transaction number on your side', type: 'string'},
                {required: true, parameter: 'withdrawType', description: 'BANK_TRANSFER, PAPARA, TETHER, PAYFIX', type: 'string'}
              ]
            },
            {
              title: 'Header',
              items: [
                {required: true, parameter: 'sign', description: 'Your Request Signature', type: 'string'},
                {required: true, parameter: 'appKey', description: 'Your Application Key', type: 'string'}
              ]
            },
            {
              title: 'Response',
              items: [
                {parameter: 'status', description: 'Process status', type: 'boolean'},
                {parameter: 'transactionId', description: 'Transaction ID', type: 'string'},
                {parameter: 'message', description: 'Process message', type: 'string'},
              ]
            }
          ]
        },
        {
          title: 'Create Bank Transfer Deposit',
          text: 'It creates a deposit transaction through the bank you have chosen for your site.\n Minimum amount limit: 50 TL',
          url: 'https://api.' + this.siteName + '/v1/integration/bank-transfer/deposit',
          method: 'POST',
          enum: 'BANK_TRANSFER',
          parameters: [
            {
              title: 'Body',
              items: [
                {required: true, parameter: 'amount', description: 'Amount requested to deposit', type: 'number'},
                {required: true, parameter: 'userId', description: 'ID of member', type: 'string'},
                {required: true, parameter: 'name', description: 'Full name of member', type: 'string'},
                {required: true, parameter: 'username', description: 'Username of member', type: 'string'},
                {
                  required: true,
                  parameter: 'processId',
                  description: 'Transaction number on your side',
                  type: 'string'
                },
                {
                  required: false,
                  parameter: 'successRedirectUrl',
                  description: 'If the operation is successful, the address you want the user to be forwarded to',
                  type: 'string'
                },
                {
                  required: false,
                  parameter: 'failRedirectUrl',
                  description: 'If the operation is unsuccessful, the address you want the user to be forwarded to',
                  type: 'string'
                }
              ]
            },
            {
              title: 'Header',
              items: [
                {required: true, parameter: 'sign', description: 'Your Request Signature', type: 'string'},
                {required: true, parameter: 'appKey', description: 'Your Application Key', type: 'string'}
              ]
            },
            {
              title: 'Response',
              items: [
                {parameter: 'banklist', description: 'Bank list', type: 'array'},
                {parameter: 'transactionId', description: 'Transaction ID', type: 'string'}
              ]
            }
          ]
        },
        {
          title: 'Create Payfix Deposit',
          text: 'It creates a deposit transaction through the bank you have chosen for your site.\n Minimum amount limit: 50 TL',
          url: 'https://api.' + this.siteName + '/v1/integration/payfix/deposit',
          method: 'POST',
          enum: 'PAYFIX',
          parameters: [
            {
              title: 'Body',
              items: [
                {required: true, parameter: 'amount', description: 'Amount requested to deposit', type: 'number'},
                {required: true, parameter: 'userId', description: 'ID of member', type: 'string'},
                {required: true, parameter: 'name', description: 'Name of member', type: 'string'},
                {required: true, parameter: 'surname', description: 'Surname of member', type: 'string'},
                {required: true, parameter: 'username', description: 'Username of member', type: 'string'},
                {
                  required: true,
                  parameter: 'processId',
                  description: 'Transaction number on your side',
                  type: 'string'
                },
                {
                  required: false,
                  parameter: 'successRedirectUrl',
                  description: 'If the operation is successful, the address you want the user to be forwarded to',
                  type: 'string'
                },
                {
                  required: false,
                  parameter: 'failRedirectUrl',
                  description: 'If the operation is unsuccessful, the address you want the user to be forwarded to',
                  type: 'string'
                },
              ]
            },
            {
              title: 'Header',
              items: [
                {required: true, parameter: 'sign', description: 'Your Request Signature', type: 'string'},
                {required: true, parameter: 'appKey', description: 'Your Application Key', type: 'string'}
              ]
            },
            {
              title: 'Response',
              items: [
                {parameter: 'status', description: 'Process status', type: 'boolean'},
                {parameter: 'transactionId', description: 'Transaction ID', type: 'string'},
                {
                  parameter: 'redirect',
                  description: 'In order for the member to pay by credit card, you must direct to this address.',
                  type: 'string'
                },
              ]
            }
          ]
        },
        {
          title: 'Create Payco Deposit',
          text: 'It creates a deposit transaction through the bank you have chosen for your site.\n Minimum amount limit: 50 TL',
          url: 'https://api.' + this.siteName + '/v1/integration/payco/deposit',
          method: 'POST',
          enum: 'PAYCO',
          parameters: [
            {
              title: 'Body',
              items: [
                {required: true, parameter: 'amount', description: 'Amount requested to deposit', type: 'number'},
                {required: true, parameter: 'userId', description: 'ID of member', type: 'string'},
                {required: true, parameter: 'name', description: 'Name of member', type: 'string'},
                {required: true, parameter: 'surname', description: 'Surname of member', type: 'string'},
                {required: true, parameter: 'username', description: 'Username of member', type: 'string'},
                {
                  required: true,
                  parameter: 'processId',
                  description: 'Transaction number on your side',
                  type: 'string'
                },
                {
                  required: false,
                  parameter: 'successRedirectUrl',
                  description: 'If the operation is successful, the address you want the user to be forwarded to',
                  type: 'string'
                },
                {
                  required: false,
                  parameter: 'failRedirectUrl',
                  description: 'If the operation is unsuccessful, the address you want the user to be forwarded to',
                  type: 'string'
                },
              ]
            },
            {
              title: 'Header',
              items: [
                {required: true, parameter: 'sign', description: 'Your Request Signature', type: 'string'},
                {required: true, parameter: 'appKey', description: 'Your Application Key', type: 'string'}
              ]
            },
            {
              title: 'Response',
              items: [
                {parameter: 'status', description: 'Process status', type: 'boolean'},
                {parameter: 'transactionId', description: 'Transaction ID', type: 'string'},
                {
                  parameter: 'redirect',
                  description: 'In order for the member to pay by credit card, you must direct to this address.',
                  type: 'string'
                },
              ]
            }
          ]
        },
        {
          title: 'Create Credit Card Deposit',
          text: 'It allows the member to pay by credit card. You should redirect it to the "redirect" field returning from the service.\n Minimum deposit limit: 50 TL',
          url: 'https://api.' + this.siteName + '/v1/integration/card/deposit',
          method: 'POST',
          enum: 'CARD',
          parameters: [
            {
              title: 'Body',
              items: [
                {required: true, parameter: 'amount', description: 'Amount requested to deposit', type: 'number'},
                {required: true, parameter: 'userId', description: 'ID of member', type: 'string'},
                {required: true, parameter: 'name', description: 'Firstname of member', type: 'string'},
                {required: true, parameter: 'surname', description: 'Surname of member', type: 'string'},
                {required: true, parameter: 'username', description: 'Username of member', type: 'string'},
                {required: true, parameter: 'processId', description: 'Transaction number on your side', type: 'string'},
                {required: true, parameter: 'cardName', description: 'Credit Card Owner Name', type: 'string'},
                {required: true, parameter: 'cardNumber', description: 'Credit Card Number', type: 'string'},
                {required: true, parameter: 'cardCvv', description: 'Credit Card CVV code', type: 'string'},
                {required: true, parameter: 'cardMonth', description: 'Credit Card Expire Month', type: 'string'},
                {required: true, parameter: 'cardYear', description: 'Credit Card Expire Year', type: 'string'},
                {required: false, parameter: 'userPhone', description: 'Phone number of member', type: 'string'},
                {required: true, parameter: 'ip', description: 'IP address of member', type: 'string'},
                {required: false, parameter: 'currency', description: 'Currency of deposit. Default TRY.', type: 'string'},
                {
                  required: true,
                  parameter: 'successRedirectUrl',
                  description: 'If the operation is successful, the address you want the user to be forwarded to',
                  type: 'string'
                },
                {
                  required: true,
                  parameter: 'failRedirectUrl',
                  description: 'If the operation is unsuccessful, the address you want the user to be forwarded to',
                  type: 'string'
                }
              ]
            },
            {
              title: 'Header',
              items: [
                {required: true, parameter: 'sign', description: 'Your Request Signature', type: 'string'},
                {required: true, parameter: 'appKey', description: 'Your Application Key', type: 'string'}
              ]
            },
            {
              title: 'Response',
              items: [
                {parameter: 'status', description: 'Process status', type: 'boolean'},
                {parameter: 'transactionId', description: 'Transaction ID', type: 'string'},
                {
                  parameter: 'redirect',
                  description: 'In order for the member to pay by credit card, you must direct to this address.',
                  type: 'string'
                },
              ]
            }
          ]
        }
      ]
    }
  },
  mounted() {
    const html = document.getElementsByTagName('html')[0]
    html.classList = []
  }
}
</script>
<style>
.documentation-content {font-size: 14px!important}
.documentation-content .content ul {margin: 0!important}
.documentation-content .card-header-title {padding: .5rem}
.documentation-content table td {white-space: normal!important}
.documentation-content hr {margin-top: .5rem!important; margin-bottom: .5rem!important}
</style>
